<template>
  <c-table
    ref="table"
    hideBottom
    :isTitle="false"
    :usePaging="false"
    :title="`${props.row.resultType} 참가자 목록`"
    :columns="grid.columns"
    :data="props.row.users"
    :gridHeight="grid.height"
  >
    <!-- 버튼 영역 -->
    <!-- <template slot="table-button">
      <q-btn-group outline>
        <c-btn label="등록" v-if="editable" icon="add" @btnClicked="openPop" />
        <c-btn label="검색" icon="search" @btnClicked="getList" />
      </q-btn-group>
    </template> -->
  </c-table>
</template>

<script>
export default {
  name: "contentsEntrants",
  props: {
    props: {
      type: Object,
      default: function() {
        return {
          row: {},
        }
      },
    },
    col: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "userName",
            field: "userName",
            label: "이름",
            align: "center",
            style: "width:150px",
            sortable: true,
          },
        ],
        height: '300px'
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  },
};
</script>
